'use client'

import './Header.scss'
import { clsx } from 'clsx'
import { usePathname } from 'next/navigation'
import { ReactNode, useEffect, useState } from 'react'
import { NavLink } from '@/components/NavLink'
import { HeaderData } from '@/data/data'
import { useHover } from '@/hooks/useHover'

interface Props {
  headerData: HeaderData
}

export function Header(props: Props): ReactNode {
  const [servicesRef, servicesHover, setServicesHover] = useHover()
  const [drawerOpen, setDrawerOpen] = useState(false)

  // This is to close the Drawer only when navigation has completed
  // Otherwise, it creates an unwanted flicker
  const pathname = usePathname()
  // biome-ignore lint/correctness/useExhaustiveDependencies: ok
  useEffect(() => {
    if (drawerOpen) {
      hideDrawer()
    }
  }, [pathname])

  function hideHover(): void {
    setServicesHover(false)
  }

  function hideDrawer(): void {
    setDrawerOpen(false)
    setOverflow(true)
  }

  function mobileNav(href: string): void {
    if (href === pathname) {
      // This is needed, because "nagivation" doesn't happen
      hideDrawer()
    }
  }

  function toggleDrawer(): void {
    setOverflow(drawerOpen)
    setDrawerOpen(!drawerOpen)
  }

  function setOverflow(enabled: boolean): void {
    document.body.style.overflow = enabled ? '' : 'hidden'
  }

  return (
    <>
      <header className="header mobile-hidden">
        <div className="header__item header__item--services" ref={servicesRef}>
          <NavLink
            href={props.headerData.menu[0]!.link}
            className="header__link"
            onClick={hideHover}
          >
            {props.headerData.menu[0]!.label}
          </NavLink>

          <div
            className="header__dropdown"
            style={{ visibility: servicesHover ? 'visible' : 'hidden' }}
          >
            <ul className="header__menu-list">
              {props.headerData.menu[0]!.subMenu?.map(internalLink => (
                <NavLink
                  href={internalLink.url}
                  className="header__dropdown-link"
                  onClick={hideHover}
                  key={internalLink.url}
                >
                  {internalLink.text}
                </NavLink>
              ))}
            </ul>
          </div>
        </div>

        {props.headerData.menu.slice(1, 2).map(headerLink => (
          <NavLink href={headerLink.link} className="header__link" key={headerLink.link}>
            {headerLink.label}
          </NavLink>
        ))}

        <NavLink href="/" className="header__link header__link_logo">
          <img className="header__logo" src="/images/mud_black_logo.svg" alt="Mud Studios logo" />
        </NavLink>

        {props.headerData.menu.slice(2).map(headerLink => (
          <NavLink href={headerLink.link} className="header__link" key={headerLink.link}>
            {headerLink.label}
          </NavLink>
        ))}
      </header>

      <header
        className="header desktop-hidden"
        style={{ backgroundColor: drawerOpen ? '#f0f2f3' : 'rgb(255 255 255 / 86%)' }}
      >
        <NavLink
          href="/"
          onClick={() => mobileNav('/')}
          className="header__link header__link_logo"
          prefetch={true}
        >
          <img className="header__logo" src="/images/mud_black_logo.svg" alt="Mud Studios logo" />
        </NavLink>
        <button className="drawer__btn" onClick={toggleDrawer}>
          <img
            src="/images/cross.svg"
            className={clsx({
              drawer__cross: true,
              drawer__cross_close: drawerOpen,
            })}
            alt="close"
            loading="lazy"
          />
        </button>
      </header>

      <div className={`desktop-hidden drawer ${drawerOpen ? 'drawer_open' : ''}`}>
        <div className="drawer__menu">
          <div className="drawer__submenu">
            <h4 className="drawer__submenu-title">{props.headerData.menu[0]!.label}</h4>

            {props.headerData.menu[0]!.subMenu?.map(internalLink => (
              <NavLink
                href={internalLink.url}
                onClick={() => mobileNav(internalLink.url)}
                className="header__submenu-link"
                prefetch={true}
                key={internalLink.url}
              >
                {internalLink.text}
              </NavLink>
            ))}
          </div>

          {props.headerData.menu.slice(1).map(headerLink => (
            <NavLink
              href={headerLink.link}
              onClick={() => mobileNav(headerLink.link)}
              className="drawer__link"
              prefetch={true}
              key={headerLink.link}
            >
              {headerLink.label}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  )
}
