'use client'

import './Footer.scss'
import { _stringify, getFetcher } from '@naturalcycles/js-lib'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { FormEvent, ReactNode, useState } from 'react'
import { FooterData } from '@/data/data'

interface FooterProps {
  footerData: FooterData
}

export function Footer(props: FooterProps): ReactNode {
  const pathname = usePathname()
  const studioRentalPage = pathname === '/services/studio-rental'
  const contactPage = pathname === '/contact'
  const selectedCasesPage = pathname.startsWith('/selected-cases/')
  const mudLabPage = pathname === '/mud-lab'
  const year = new Date().getFullYear()

  const [subscribed, setSubscribed] = useState(false)

  if (contactPage || selectedCasesPage || mudLabPage || studioRentalPage) {
    return null
  }

  async function submitForm(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const email = (e.target as HTMLFormElement)['email'].value
    try {
      await getFetcher().postText('https://subscribe-6j3acobjca-ew.a.run.app', { json: { email } })
      // alert('Subscribed!')
      setSubscribed(true)
    } catch (err) {
      alert('Something went wrong\n\n' + _stringify(err))
    }
  }

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__navlinks">
          {props.footerData.internalLinks?.map(link => (
            <Link href={link.url} className="footer__navlink" key={link.url}>
              {link.text}
            </Link>
          ))}
          {/* <NavLink to="#" className="footer__navlink">*/}
          {/*  careers*/}
          {/* </NavLink>*/}
        </div>
        <form className="footer__subscribe-form" onSubmit={submitForm}>
          <label className="footer__input-label" htmlFor="email">
            {props.footerData.newsletterLabel}
          </label>
          {!subscribed && (
            <div className="footer__input-field">
              <input
                className="footer__email-input"
                type="email"
                id="email"
                placeholder={props.footerData.newsletterPlaceholder}
                required
              />
              <button className="footer__submit-btn" type="submit" aria-label="Submit" />
            </div>
          )}
          {subscribed && (
            <div className="footer__input-field_subscribed">
              <div className="footer__email-input">Subscribed!</div>
            </div>
          )}
        </form>

        {props.footerData.contactBlocks?.map((block, i) => (
          <div className={`footer__list-item footer__list-item_${i + 1}`} key={block.url}>
            <h3 className="footer__subtitle">{block.label}</h3>
            <a className="footer__contact" href={block.url}>
              {block.text}
            </a>
          </div>
        ))}

        <div className="footer__sm-links">
          {props.footerData.socialMediaLinks?.map(link => (
            <a className="footer__sm-link" key={link.url} href={link.url} target="_blank">
              <img className="footer__sm-img" src={link.icon.url} alt={link.label} loading="lazy" />
            </a>
          ))}
        </div>
      </div>

      <div className="footer__bottom-container">
        <p className="footer__copyright">
          &copy; 2014–{year} {props.footerData.copyright}
        </p>
        {props.footerData.mudLogo && (
          <img
            className="footer__logo"
            src={props.footerData.mudLogo.url}
            alt={props.footerData.mudLogo.alt}
            loading="lazy"
          />
        )}
      </div>
    </footer>
  )
}
