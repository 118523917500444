'use client'
import { usePathname } from 'next/navigation'
import React, { ReactNode, useEffect } from 'react'
import { adminService } from '@/admin'
import { pageView, setupAnalytics } from '@/analytics'
import { aktivGrotesk, ebGaramond, robotoCondensed } from '@/app/fonts'
import { Dropup } from '@/components/Dropup'
import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { FooterData, HeaderData } from '@/data/data'
import { initSentry } from '@/sentry'

interface AppLayoutProps {
  children: ReactNode
  footerData: FooterData
  headerData: HeaderData
  draftMode: boolean
}

export function AppLayout(props: AppLayoutProps): ReactNode {
  const pathname = usePathname()

  // This runs "only once, on first page load"
  // biome-ignore lint/correctness/useExhaustiveDependencies: ok
  useEffect(() => {
    initSentry()
    setupAnalytics()
    if (props.draftMode && !adminService.adminMode) {
      void adminService.toggleRedDot()
    }
  }, [])

  // This runs on every "pageView"
  useEffect(() => {
    // This is a fix for Next.js not scrolling to the top on page navigation
    // https://github.com/vercel/next.js/discussions/64435#discussioncomment-9101547
    window.scroll(0, 0)

    // console.log({pathname})
    pageView({
      page_title: document.title,
      page_location: pathname + location.search,
    })
  }, [pathname])

  return (
    <html
      lang="en-US"
      className={`${robotoCondensed.variable} ${ebGaramond.variable} ${aktivGrotesk.variable}`}
    >
      <body>
        <Header headerData={props.headerData} />
        <div className="page" />
        {props.children}
        <Footer footerData={props.footerData} />
        <Dropup />
        <script
          type="application/ld+json"
          // biome-ignore lint/security/noDangerouslySetInnerHtml: ok
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              url: 'https://mudstudios.com',
              name: 'Mud Studios',
              logo: 'https://mudstudios.com/google_website-480x480-1.jpg',
            }),
          }}
        />
      </body>
    </html>
  )
}
